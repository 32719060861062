<template>
  <!-- <div>{{ clinicData }}상품정보 관리</div> -->
  <v-tab-item :transition="false">
    <v-card flat class="editorPadding">
      <ProductType
        ref="refProductType"
        :clinicData="clinicData"
        :productTypeData="productTypeData"
        @saveComplete="_infoUpdate"
      />

      <div class="vGap" />

      <ProductTypeItem
        ref="refProductTypeItem"
        :clinicData="clinicData"
        :productTypeData="productTypeData"
      />
    </v-card>
  </v-tab-item>
</template>
<script>
import { mapActions } from "vuex";
import ProductType from "./type/ProductType.vue";
import ProductTypeItem from "./item/ProductTypeItem.vue";
export default {
  components: { ProductType, ProductTypeItem },
  props: {
    clinicData: {
      type: [Object],
    },
  },
  data() {
    return {
      productTypeData: [],
    };
  },
  methods: {
    _infoUpdate() {
      this.getProductTypeData();
      setTimeout(() => {
        this.$refs.refProductTypeItem._getData();
      }, 300);
    },
    getProductTypeData() {
      const $h = this.$helper;
      this.productTypeData = [];
      this.clinicData.type_id = 0;
      this.clinicData.endpoint = "productType";
      this.getDataQuery(this.clinicData).then((resP) => {
        if (resP === undefined) return;
        let res = resP.data;
        this.footerHide = res.length > 5 ? false : true;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
            dpDateLabel: $h.changeDateToForUpdateDp(item.regDate, item.updDate),
            value: item.type_id,
            text: item.productTypeName,
          }));
        }
        this.productTypeData = res;
      });
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>
<style lang="scss">
.vGap {
  height: 50px;
}
</style>