<template>
  <v-dialog v-model="visible" max-width="800px" scrollable>
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> [{{ patientData.name }}]님의 문의내용입니다 </v-card-title>

      <v-expansion-panels class="my-4">
        <v-expansion-panel
          v-for="(item, i) in patientData.patientInquiry"
          :key="i"
          class=""
          elevation="1"
        >
          <v-expansion-panel-header class="border-b-2 border-gray-600">
            <div
              class="d-flex justify-space-between sm:align-center align-start"
            >
              <div
                class="md:flex block justify-space-between sm:align-center align-start flex-1"
              >
                <div class="flex-grow-1" style="flex-basis: 136px">
                  <p class="text-uppercase ma-0 mb-3 mr-4">
                    {{ item.title }}
                  </p>
                </div>
                <div
                  class="flex-grow-1 text-left mr-2"
                  style="flex-basis: 136px"
                >
                  <p class="ma-0 caption regDate">
                    최근업데이트일 :
                    {{
                      $helper.changeDateToForUpdateDp(
                        item.regDate,
                        item.updDate
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="font-weight-medium">
            <p class="ma-0">
              {{ item.contents }}
            </p>

            <!--  -->
            <v-btn color="primary" outlined small @click="replay(item)">
              병원답변
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <!-- 병원상담 답변 -->
    <DialogUserInquiryReply
      ref="refDialogUserInquiryReply"
      @complete="replayComplete"
    />
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "./comp/Header.vue";
import DialogUserInquiryReply from "@/components/commonV2/Dialog/DialogUserInquiryReply.vue";

export default {
  components: {
    Header,
    DialogUserInquiryReply,
  },
  data() {
    return {
      visible: false,
      patientData: {},
      inquiryData: {},
    };
  },
  methods: {
    showModal(patientData) {
      this.visible = true;
      this.patientData = patientData;
      this.inquiryData = patientData.patientInquiry[0];
      console.log("inquiry in patientData", patientData);
    },
    replay(item) {
      // console.log("item", item);
      this.$refs.refDialogUserInquiryReply.showModal(this.patientData, item);
    },
    replayComplete() {},
    close() {
      this.visible = false;
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
.regDate {
  color: gray;
}
</style>