<template>
  <!-- <div>{{ clinicData }}상품정보 관리</div> -->
  <v-tab-item :transition="false">
    <v-card flat class="editorPadding">
      <ManagerInfos ref="refManagerInfos" :clinicData="clinicData" />

      <!-- <div class="vGap" /> -->
    </v-card>
  </v-tab-item>
</template>
<script>
import ManagerInfos from "./managerInfos/ManagerInfos.vue";
export default {
  components: {
    ManagerInfos,
  },
  props: {
    clinicData: {
      type: [Object],
    },
  },
  methods: {
    _infoUpdate() {
      setTimeout(() => {
        this.$refs.refManagerInfos._getData();
        // this.$refs.refEncounter._getData();
      }, 300);
    },
  },
};
</script>
<style lang="">
</style>