<template>
  <vue-perfect-scrollbar :settings="{ suppressScrollX: true, wheelPropagation: false }" class="h-100 rtl-ps-none ps scroll" style="height: 100%">
    <div class="pa-5 relative">
      <v-app-bar :color="$vuetify.theme.dark ? 'dark' : 'grey lighten-5'" :dark="$vuetify.theme.dark" class="" fixed flat>
        <div class="d-flex justify-space-between align-center w-full">
          <h6 class="ma-0">
            <!-- [{{ patientData && patientData.name }}]{{ $t("patient.title") }} -->
            <!-- [{{ clinicData && clinicData.hospitalName }}] 병원정보 관리 -->
            [{{ clinicData && $helper.definedStringI18($helper.makeStringToArray(clinicData.hospitalName, "@@"), 'kr') }}] 병원정보 관리
          </h6>
          <slot name="userDrawerCloseButton" /> 
        </div>
      </v-app-bar>
    </div>

    <v-tabs class="thisTabs" v-model="tabIdx" align-tabs="center" bg-color="deep-purple-accent-4" stacked @change="tabClick">
      <v-tab> 의사선냉(교수)님정보관리 </v-tab>
      <v-tab> 담당자정보관리 </v-tab>
      <v-tab> 이벤트상품관리 </v-tab>
      <v-tab> 진료상품정보관리 </v-tab>
      <!-- <v-tab> 상품타입관리 </v-tab> -->
      <v-tab> 병원 이미지,영상 관리 </v-tab>
      <!-- <v-tab> 병원 예약관리 </v-tab> -->
    </v-tabs>

    <v-tabs-items v-model="tabIdx">
      <Director ref="refDirector" :clinicData="clinicData" />
      <Manager ref="refManager" :clinicData="clinicData" />
      <Event ref="refEvent" :clinicData="clinicData" />
      <Product ref="refProduct" :clinicData="clinicData" />
      <!-- <ProductTypeItem ref="refProductTypeItem" :clinicData="clinicData" /> -->
      <Contents ref="refContents" :clinicData="clinicData" />
      <!-- <Booking ref="refBooking" :clinicData="clinicData" /> -->
    </v-tabs-items>
  </vue-perfect-scrollbar>
</template>
<script>
import { mapActions } from "vuex";
import Manager from "./manager/Manager.vue";
import Director from "./director/Director.vue";
import Event from "./event/Event.vue";
import Product from "./product/Product.vue";
import ProductTypeItem from "./productTypeItem/ProductTypeItem.vue";
import Contents from "./contents/Contents.vue";
import Booking from "./booking/Booking.vue";

export default {
  components: {
    Manager,
    Director,
    Product,
    Event,
    ProductTypeItem,
    Contents,
    Booking,
  },
  data() {
    return {
      tabIdx: 0,
      clinicData: null,
    };
  },
  watch: {},
  methods: {
    _updateData(_clinicData) {
      console.log("basicTabs _clinicData", _clinicData);
      this.clinicData = _clinicData;
      this.tabClick(this.tabIdx);
    },
    tabClick(e) {
      console.log("e", e);
      this.tabIdx = e;
      setTimeout(() => {
        if (e == 0) this.$refs.refDirector._infoUpdate();
        if (e == 1) this.$refs.refManager._infoUpdate();
        if (e == 2) this.$refs.refEvent._infoUpdate();
        if (e == 3) this.$refs.refProduct._infoUpdate();
        // if (e == 4) this.$refs.refProductTypeItem._infoUpdate();
        if (e == 4) this.$refs.refContents._infoUpdate();
      }, 100);
    },
    // ...mapActions(["setTimer"]),
  },
};
</script>
<style lang="scss">
header.grey.lighten-5.theme--light.v-app-bar.v-app-bar--fixed.v-sheet.v-toolbar.v-toolbar--flat {
  top: 0;
}

.thisTabs {
  margin-top: 40px !important;
}
</style>