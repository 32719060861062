<template>
  <v-card flat>
    <v-card-text>
      <p>
        Sed aliquam ultrices mauris. Donec posuere vulputate arcu. Morbi ac
        felis. Etiam feugiat lorem non metus. Sed a libero.
      </p>

      <p class="mb-0">
        Phasellus dolor. Fusce neque. Fusce fermentum odio nec arcu.
        Pellentesque libero tortor, tincidunt et, tincidunt eget, semper nec,
        quam. Phasellus blandit leo ut odio.
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    clinicData: {
      type: [Object, Array],
    },
  },
};
</script>
<style lang="">
</style>