<template>
  <SelectBox
    label="진료상품"
    selectStyleWidth="width: 100%"
    :value="selectedId"
    :items="productData"
    :multiple="true"
    :chips="true"
    @changeSelect="changeSelect"
  />
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
const modelService = require("@/utils/dataModel");

export default {
  components: { SelectBox },
  props: {
    selectedId: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      productData: [],
      reqData: modelService.hospitalProductModel(),
    };
  },
  methods: {
    getProductData(clinicData) {
      const $h = this.$helper;
      this.productData = [];
      this.reqData.hospital_id = clinicData.hospital_id;
      this.getDataQuery(this.reqData).then((resP) => {
        if (resP === undefined) return;
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
            dpDateLabel: $h.changeDateToForUpdateDp(item.regDate, item.updDate),
            value: item.product_id,
            text: $h.definedStringI18($h.makeStringToArray(item.productName, "@@"), 'kr') //item.productTypeName,
          }));
        }
        this.productData = res;
        console.log('res', res);
      });
    },
    changeSelect(e) {
      this.$emit("changeSelect", e);
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>
<style lang="">
</style>