<template>
  <div>
    <div class="top">
      <div class="tableTitle">{{ title }}</div>
      <!-- <div class="btnPos">
        <v-btn color="primary" depressed small @click="openSetting"
          >예약가능일정 설정</v-btn
        >
        <v-btn color="primary" depressed small @click="openModal">Add</v-btn>
      </div> -->
    </div>

    <!-- <div class="topOption">
      <v-btn text color="secondary"> 전체 </v-btn>
      <v-btn text color="info"> 상담 </v-btn>
      <v-btn text color="primary"> 견적 </v-btn>
    </div> -->

    <UserBookingPhotoCardList
      :data="patientData"
      @inquiryReply="openInquiryList"
      @inquiryPrice="inquiryPrice"
    />

    <!-- 예약리스트 정보추가 모달 -->
    <!-- <DialogUserBookingInfo ref="refEditModal" @saveComplete="saveComplete" /> -->

    <!-- 예약일정 셋팅 모달 -->
    <!-- <DialogClinicBookingSetting
      ref="refDialogClinicBookingSetting"
      @complete="settingAddComplete"
    /> -->

    <!-- 상담리스트 창 -->
    <DialogUserInquiryList ref="refDialogUserInquiryList" />

    <!-- 상담답변 창 -->
    <!-- <DialogUserInquiryReply
      ref="refDialogUserInquiryReply"
      @complete="settingAddComplete"
    /> -->

    <!-- 견적답변 창 -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
import UserBookingPhotoCardList from "./UserBookingPhotoCardList.vue";
import DataTable from "@/components/commonV2/table/DataTable.vue";
import DialogUserInquiryList from "@/components/commonV2/Dialog/DialogUserInquiryList.vue";
import DialogUserInquiryReply from "@/components/commonV2/Dialog/DialogUserInquiryReply.vue";
import DialogUserBookingInfo from "@/components/commonV2/Dialog/DialogUserBookingInfo.vue";
import DialogClinicBookingSetting from "@/components/commonV2/Dialog/DialogClinicBookingSetting.vue";
const formatService = require("@/utils/format.js");
export default {
  components: {
    UserBookingPhotoCardList,
    DataTable,
    DialogUserInquiryList,
    DialogUserInquiryReply,
    DialogUserBookingInfo,
    DialogClinicBookingSetting,
  },
  props: {
    patientData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      title: "...",
      data: [],
      tableColumns: formatService.productInfoTableColumns(),
      footerHide: false,
    };
  },
  watch: {
    patientData(val) {
      if (val === null) return;
      // this._getData();
    },
  },
  methods: {
    openSetting() {
      this.$refs.refDialogClinicBookingSetting.Open(this.patientData);
    },
    settingAddComplete() {},
    openModal() {
      this.$refs.refEditModal.showModal(this.title, this.patientData, null);
    },
    openInquiryList(item) {
      this.$refs.refDialogUserInquiryList.showModal(item);
    },
    inquiryReply(item) {
      this.$refs.refDialogUserInquiryReply.showModal(item);
    },
    inquiryPrice(item) {
      this.$refs.refEditModal.showModal(this.patientData, item);
    },
    // _getData() {
    //   const $h = this.$helper;
    //   this.data = [];
    //   this.clinicData.product_id = 0;
    //   this.clinicData.endpoint = "hospitalProduct";
    //   this.getDataQuery(this.clinicData).then((resP) => {
    //     if (resP === undefined) return;
    //     let res = resP.data;
    //     this.footerHide = res.length > 5 ? false : true;
    //     if (0 < res.length) {
    //       res = res.map((item, index) => ({
    //         ...item,
    //         no: res.length - index,
    //         regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
    //         dpDateLabel: $h.changeDateToForUpdateDp(item.regDate, item.updDate),
    //       }));
    //     }
    //     this.data = res;
    //   });
    // },
    saveComplete() {
      // this._getData();
      this.$emit("saveComplete");
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>
<style lang="scss">
.top {
  display: flex;
  margin-bottom: 10px;
}
.tableTitle {
  margin-right: auto;
  padding-top: 14px;
  font-weight: 700;
}
.btnPos {
  padding: 10px;
  button {
    margin-right: 5px !important;
  }
}
</style>