<template>
  <v-tab-item :transition="false">
    <v-card flat class="editorPadding">
      <UserBookingList ref="refUserBookingList" :clinicData="clinicData" />

      <div class="vGap" />
    </v-card>
  </v-tab-item>
</template>
<script>
import BookingSetting from "./setting/BookingSetting.vue";
import UserBookingList from "./userBooking/UserBookingList.vue";
export default {
  components: {
    BookingSetting,
    UserBookingList,
  },
  props: {
    clinicData: {
      type: [Object],
    },
  },
  data() {
    return {
      title: "예약요청 리스트",
    };
  },
  methods: {
    _infoUpdate() {
      setTimeout(() => {
        // this.$refs.refClinicImageUpload._getData();
        // this.$refs.refClinicContentsUpload._getUploadFiles();
      }, 300);
    },
  },
};
</script>
<style lang="">
</style>