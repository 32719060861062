<template>
  <v-tab-item :transition="false">
    <v-card flat class="editorPadding">
      <EventInfo ref="refEventInfo" :clinicData="clinicData" />

      <div class="vGap" />
    </v-card>
  </v-tab-item>
</template>
<script>
import EventInfo from "./EventInfo/EventInfo.vue";
export default {
  components: {
    EventInfo
  },
  props: {
    clinicData: {
      type: [Object],
    },
  },
  data() {
    return {
      // title: "예약요청 리스트",
    };
  },
  methods: {
    _infoUpdate() {
      setTimeout(() => {
        this.$refs.refEventInfo._getData();
        // this.$refs.refClinicContentsUpload._getUploadFiles();
      }, 300);
    },
  },
};
</script>
<style lang="">
</style>