<template>
  <v-tabs vertical>
    <v-tab>
      <v-icon left> mdi-account </v-icon>
      예약정보
    </v-tab>
    <v-tab>
      <v-icon left> mdi-lock </v-icon>
      병원견적
    </v-tab>
    <v-tab>
      <v-icon left> mdi-access-point </v-icon>
      병원답변
    </v-tab>

    <v-tab-item>
      <BookingInfo :clinicData="clinicData" />
    </v-tab-item>
    <v-tab-item>
      <PriceInfo :clinicData="clinicData" />
    </v-tab-item>
    <v-tab-item>
      <UserQuestionInfo :clinicData="clinicData" />
    </v-tab-item>
  </v-tabs>
</template>
<script>
import BookingInfo from "./comp/BookingInfo.vue";
import PriceInfo from "./comp/PriceInfo.vue";
import UserQuestionInfo from "./comp/UserQuestionInfo.vue";
export default {
  components: { BookingInfo, PriceInfo, UserQuestionInfo },
  props: {
    clinicData: {
      type: [Object, Array],
    },
  },
};
</script>
<style lang="">
</style>