<template>
  <v-dialog v-model="visible" max-width="800px" scrollable>
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> {{ editType }} </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-text-field
              label="담당자명"
              type="text"
              ref="refManagerName"
              v-model="targetModel.managerName"
              :rules="[filedRules.required]"
            />
          </v-row>
          <v-row>
            <v-text-field
              label="연락처(휴대폰)"
              type="text"
              ref="refCell"
              v-model="targetModel.cell"
              :rules="[filedRules.required]"
            />
          </v-row>
          <v-row>
            <v-text-field
              label="이메일"
              type="text"
              ref="refEmail"
              v-model="targetModel.email"
              :rules="[
                emailRules.required,
                emailRules.counter,
                emailRules.email,
              ]"
            />
          </v-row>
          <v-row>
            <v-text-field
              label="메신저계정(weChat)"
              type="text"
              ref="refWeChat"
              v-model="targetModel.messengerId.weChat"
              :rules="[
                emailRules.required,
                emailRules.counter,
                emailRules.email,
              ]"
            />
          </v-row>
          <v-row>
            <v-text-field
              label="메신저계정(whatsApp)"
              type="text"
              ref="refWhatsApp"
              v-model="targetModel.messengerId.whatsApp"
              :rules="[emailRules.email]"
            />
          </v-row>
          <v-row>
            <v-text-field
              label="메신저계정(instagram)"
              type="text"
              ref="refInstagram"
              v-model="targetModel.messengerId.instagram"
              :rules="[emailRules.email]"
            />
          </v-row>
          <v-row>
            <v-text-field
              label="메신저계정(faceBook)"
              type="text"
              ref="refFaceBook"
              v-model="targetModel.messengerId.faceBook"
              :rules="[emailRules.email]"
            />
          </v-row>
          <v-row>
            <v-text-field
              label="메신저계정(kakaoTalk)"
              type="text"
              ref="refKakaoTalk"
              v-model="targetModel.messengerId.kakaoTalk"
              :rules="[emailRules.email]"
            />
          </v-row>
          <v-row>
            <v-text-field
              label="메신저계정(line)"
              type="text"
              ref="refLine"
              v-model="targetModel.messengerId.line"
              :rules="[emailRules.email]"
            />
          </v-row>
        </v-form>
      </v-card-text>

      <CommonBtn
        :propsKey="targetModel.manager_id"
        @save="clickSave"
        @delete="deleteData"
        @cancel="clickCancel"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "./comp/Header.vue";
import CommonBtn from "./medicalInfo/comp/CommonBtn.vue";
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
// import BeforeAndAfterUpload from "./comp/BeforeAndAfterUpload.vue";

// import SelectBoxProductType from "@/components/commonV2/ui/SelectBoxProductType.vue";
// import SelectBoxProductTypeItem from "@/components/commonV2/ui/SelectBoxProductTypeItem.vue";
// import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
const rulesService = require("@/utils/rules.js");
const modelService = require("@/utils/dataModel.js");
const formatService = require("@/utils/format.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    Header,
    CommonBtn,
    SelectBox,
    // BeforeAndAfterUpload,
    // SelectBoxProductType,
    // SelectBoxProductTypeItem,
    // RadioComps,
  },
  data() {
    return {
      visible: false,
      editType: "",
      // selectedProductTypeId: 0,
      // selectedProductTypeItemId: 0,
      // itemsProductType: [], //formatService.productTypeList(),
      // isCanTaxRefund: "Y",
      // isCanTaxRefundItem: formatService.isCanTaxRefundItem(),
      // serviceList: formatService.serviceList(),
      // diagnosisList: formatService.diagnosisList(),
      emailRules: rulesService.emailRules(),
      requiredMsg: requireService.requiredFieldAlertMessage(),
      targetModel: modelService.hospitalManagersModel(),
      filedRules: rulesService.filedRules(),
      clinicData: null,
    };
  },
  methods: {
    showModal(editType, clinicData, item) {
      this.clinicData = clinicData;
      this.visible = true;
      this.editType = editType;
      if (item === null) {
        this.targetModel = modelService.hospitalManagersModel();
      } else {
        this.targetModel = item;
      }
      this.targetModel.hospital_id = clinicData.hospital_id;
      this.targetModel.endpoint = "hospitalManagers";
    },
    close() {
      this.visible = false;
    },
    // isCanTaxRefundChangeType(e) {
    //   this.targetModel.isCanTaxRefund = e;
    // },
    // productTypeChangeSelect(e) {
    //   this.selectedProductTypeId = e;
    //   this.targetModel.type_id = e;
    //   this.$refs.refSelectBoxProductTypeItem.getProductTypeItemData(
    //     this.clinicData,
    //     e
    //   );
    // },
    // productTypeItemChangeSelect(e) {
    //   this.targetModel.item_id = e;
    // },
    // checkTargetFiles(e) {
    //   this.targetModel.uploadFiles = e;
    //   // hospital_id / product_id추가
    //   if (this.targetModel.uploadFiles.length > 0) {
    //     this.targetModel.uploadFiles.forEach((element) => {
    //       element.hospital_id = this.targetModel.hospital_id;
    //       element.product_id = this.targetModel.product_id;
    //     });
    //   }
    // },
    clickSave() {
      // console.log("saveData", this.targetModel);
      // return;
      const $h = this.$helper;
      const D = this.targetModel;
      let RF = this.$refs;
      // let msg = `상품타입 ${this.requiredMsg}`;
      // if (!$h.requiredCheck(D.type_id, null, msg, false)) return;

      // msg = `Drug Name ${this.requiredMsg}`;
      // if (!$h.requiredCheck(D.drugName, null, msg, false)) return;

      if (!this.$refs.form.validate()) return;
      // return;
      this.saveData();
    },
    clickCancel() {
      this.visible = false;
    },
    deleteData() {
      if (!confirm("delete?")) return;
      this.targetModel.useYN = "N";
      this.saveData();
    },
    saveData() {
      // console.log('saveData', this.targetModel);
      this.saveDataQuery(this.targetModel).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage("", "Saved", "success");
        this.visible = false;
        this.$emit("saveComplete");
      });
    },
    ...mapActions(["saveDataQuery"]),
  },
};
</script>