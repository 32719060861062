<template>
  <v-dialog v-model="visible" max-width="800px" scrollable>
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> [{{ patientData.name }}]님의 문의내용입니다 </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <h2>{{ inquiryData && inquiryData.title }}</h2>
            <h4>
              {{ inquiryData && inquiryData.contents }}
            </h4>
            <div
              v-if="
                inquiryReplyModel.uploadFiles &&
                inquiryReplyModel.uploadFiles.length > 0
              "
            >
              상담요청 첨부사진
              <div class="upload-imgs">
                <!-- 사진리스트 -->
                <div
                  v-for="(photo, i) in inquiryReplyModel.uploadFiles"
                  :key="i"
                >
                  <div class="img-mask">
                    <img :src="photo.fileUrl" @click="clickImage(photo)" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              상담요청 일자 :
              {{
                inquiryData &&
                $helper.changeDateToStringForDp(inquiryData.regDate, true)
              }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              ref="refDescription"
              v-model="inquiryReplyModel.contents"
              label="병원답변"
              placeholder=""
            />
          </v-col>
        </v-row>
      </v-card-text>

      <BtnBox @save="saveClinicReply" />
    </v-card>

    <DialogImageViewer ref="refDialogImageViewer" />
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "./comp/Header.vue";
import BtnBox from "./comp/BtnBox.vue";
import DialogImageViewer from "@/components/commonV2/Dialog/DialogImageViewer.vue";
import BookingInfoTabs from "@/_kimCare/clinic/basicTabs/booking/userBooking/userBookingInfo/_tabs.vue";
const rulesService = require("@/utils/rules.js");
const modelService = require("@/utils/dataModel.js");
const formatService = require("@/utils/format.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    Header,
    BookingInfoTabs,
    BtnBox,
    DialogImageViewer,
  },
  data() {
    return {
      visible: false,
      patientData: {},
      inquiryData: {},
      inquiryReplyModel: modelService.inquiryReplyModel(),
    };
  },
  methods: {
    showModal(patientData, inquiryData) {
      this.visible = true;
      this.patientData = patientData;
      this.inquiryData = inquiryData;
      // console.log("inquiry in patientData", patientData);

      this.inquiryReplyModel.reply_id = 0;
      this.inquiryReplyModel.contents = "";
      this.getHospitalReply();
    },
    close() {
      this.visible = false;
    },
    async getHospitalReply() {
      this.patientData.endpoint = "inquiryReply";
      this.patientData.inquiry_id = this.inquiryData.inquiry_id;
      await this.getDataQuery(this.patientData).then((resP) => {
        if (resP === undefined) return;
        if (resP.reply_id === 0) return;
        this.inquiryReplyModel.reply_id = resP.reply_id;
        this.inquiryReplyModel.contents = resP.contents;
        this.inquiryReplyModel.uploadFiles = resP.uploadFiles;
        console.log("getHospitalReply", this.inquiryReplyModel);

        setTimeout(() => {
          this.$refs.refDescription.focus();
        }, 300);
      });
    },
    async saveClinicReply() {
      console.log("inquiryData", this.inquiryData);
      this.inquiryReplyModel.hospital_id = this.patientData.hospital_id;
      this.inquiryReplyModel.user_id = this.patientData.user_id;
      this.inquiryReplyModel.inquiry_id = this.inquiryData.inquiry_id;
      // console.log("inquiryReplyModel", this.inquiryReplyModel);
      // return;
      await this.saveDataQuery(this.inquiryReplyModel).then((resP) => {
        if (resP === undefined) return;
        console.log("", resP);
        this.$helper.showTostMessage("", "Saved", "success");
        this.visible = false;
      });
    },
    clickImage(photo) {
      this.$refs.refDialogImageViewer.Open(photo.fileUrl);
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
.upload-imgs {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  > div {
    padding: 4px;
    position: relative;
    width: calc(20% - 8px);
    height: 100%;
    aspect-ratio: auto 1 / 1;
  }
  .img-mask {
    height: 100%;
    border-radius: 8px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>