<template>
  <v-tab-item :transition="false">
    <v-card flat class="editorPadding">
      <DirectorInfos ref="refDirectorInfos" :clinicData="clinicData" />

      <!-- <div class="vGap" /> -->
    </v-card>
  </v-tab-item>
</template>
<script>
import DirectorInfos from "./directorInfos/DirectorInfos.vue";
export default {
  components: {
    DirectorInfos,
  },
  props: {
    clinicData: {
      type: [Object],
    },
  },
  methods: {
    _infoUpdate() {
      setTimeout(() => {
        this.$refs.refDirectorInfos._getData();
        // this.$refs.refEncounter._getData();
      }, 300);
    },
  },
};
</script>
<style lang="">
</style>