<template>
  <div>
    <div class="top">
      <div class="tableTitle">{{ title }}</div>
      <div class="btnPos">
        <v-btn color="primary" depressed small @click="openModal">Add</v-btn>
      </div>
    </div>

    <DataTable
      ref="DataTable"
      :loading="loading"
      :loadingText="loadingText"
      :tableKey="'rowSeq'"
      :footerHide="footerHide"
      :singleSelect="true"
      :showSelect="false"
      :columns="tableColumns"
      :inputData="productTypeData"
      @clickRow="clickRow"
    />

    <DialogProductType ref="refEditModal" @saveComplete="saveComplete" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import DataTable from "@/components/commonV2/table/DataTable.vue";
import DialogProductType from "@/components/commonV2/Dialog/DialogProductType.vue";
const formatService = require("@/utils/format.js");
export default {
  components: {
    DataTable,
    DialogProductType,
  },
  props: {
    clinicData: {
      type: [Object, Array],
    },
    productTypeData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      title: "상품타입",
      loading: false,
      loadingText: "Loading list...",
      // data: [],
      tableColumns: formatService.productTypeTableColumns(),
      footerHide: false,
    };
  },
  watch: {
    clinicData(val) {
      if (val === null) return;
      // this._getData();
    },
    productTypeData(val) {
      if (val === null) return;
      this.footerHide = val.length > 5 ? false : true;
    },
  },
  methods: {
    openModal() {
      this.$refs.refEditModal.showModal(this.title, this.clinicData, null);
    },
    clickRow(item) {
      this.$refs.refEditModal.showModal(this.title, this.clinicData, item);
    },
    saveComplete() {
      this.$emit("saveComplete");
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>
<style lang="scss">
.top {
  display: flex;
  margin-bottom: 10px;
}
.tableTitle {
  margin-right: auto;
  padding-top: 14px;
  font-weight: 700;
}
</style>