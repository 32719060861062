<template>
  <div>
    <div class="profile-img">
      <div class="img-wrap">
        <div v-if="fileInfo !== null" class="img-mask">
          <img :src="fileInfo.fullURL" :alt="fileInfo.filename" />
        </div>
        <div v-else class="img-mask">
          <img
            for="file-input"
            :src="require(`@/assets/images/default/${defImage}.png`)"
          />
        </div>

        <!-- 항상 노출(안보이는)-->
        <div class="image-upload">
          <!-- v-btn쓰면 안됨 -->
          <label
            class="v-btn"
            for="file-input"
            outlined
            fab
            x-small
            color="primary"
          >
            <!-- <a-icon> mdi-camera</a-icon> -->
            <!-- <a-icon type="camera" /> -->
          </label>
          <input
            id="file-input"
            :accept="accept"
            type="file"
            @change="fileSelect"
          />
        </div>
      </div>

      <!-- 파일명 -->
      <div class="fileNameBox">
        {{ fileInfo && fileInfo.filename }}
      </div>
    </div>
  </div>
</template>
<script>
const rulesService = require("@/utils/rules.js");
import AWS from "aws-sdk";
import $ from "jquery";

export default {
  props: {
    uploadType: {
      default: "",
      type: String,
    },
    // 초기 표시 이미지
    defImage: {
      default: "",
      type: String,
    },
    // 초기 표시 라벨
    defLabel: {
      default: "",
      type: String,
    },
    // 업로드 할 디렉토리 * 없으면 빈값~
    uploadDir: {
      default: "",
      type: String,
    },
    // 파일해더 * 업로드 된 파일의 헤더(*필수로 넣어주세요) 예: RPM-File_YYYYMMDDHHmmss.jpg
    fileHeader: {
      default: "",
      type: String,
    },
    // 업로드 된 파일 정보를 받은경우
    fileInfo: {
      default: null,
      type: Object,
    },
    disabled: {
      type: Boolean,
    },
    accept: {
      default: "",
      type: String,
    },
    maxSize: {
      default: 800,
      type: Number,
    },
    zipRatio: {
      default: 0.5,
      type: Number,
    },
  },
  data() {
    return {
      albumBucketName: rulesService.predict_s3Config().S3_BUCKET,
      bucketRegion: rulesService.predict_s3Config().S3_REGION,
      IdentityPoolId: rulesService.predict_s3Config().S3_POOLID,
      selectFile: null,
      s3: null,
    };
  },
  created() {
    AWS.config.update({
      region: this.bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.IdentityPoolId,
      }),
    });
    this.s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: { Bucket: this.albumBucketName },
    });
  },
  methods: {
    _reset() {
      $("input[type=file]").val("");
      this.checkbox = false;
      this.checkbox_label = "";
    },
    makeFileName(data) {
      const returnDate = data === "" || data === null ? "" : data.split("/");
      return returnDate[2];
    },
    // 첨부파일 선택
    fileSelect(file) {
      const _self = this;
      // alert(this.fileHeader);
      // return;
      // this.selectFile = file.target.files[0];
      this.$emit("uploadStarted");
      const files = file.target.files;
      const filesArr = Array.prototype.slice.call(files);
      // 여러장의 이미지를 불러올 경우, 배열화

      filesArr.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          // 뭔가 썸팅 할 것을 넣습니다.
          // console.log("", e);
          const image = new Image();
          image.className = "img-item"; // 스타일 적용을 위해
          image.src = e.target.result;
          image.onload = () => {
            // 이미지가 로드가 되면! 리사이즈 함수가 실행되도록 합니다.
            // this.imageResize(image);
            const dataUrl = this.$helper.imageResize(
              image,
              this.maxSize,
              this.zipRatio
            );
            _self.selectFile = dataUrl;
            console.log("", _self.selectFile);
            this.uploadFileToS3(this.$helper.dataURLToBlob(_self.selectFile));
          };
        };
        reader.readAsDataURL(file);
      });
    },
    // S3 Upload Action
    uploadFileToS3(element) {
      const _self = this;
      if (this.selectFile === null) {
        this.$emit("uploadComplete", null);
        return;
      }
      // const photoKey = this.selectFile.name; // 파일명
      const getTime = this.$moment().format("YYYYMMDDHHmmss");
      // const _lastDot = photoKey.lastIndexOf(".");
      // const _fileExt = photoKey
      //   .substring(_lastDot, photoKey.length)
      //   .toLowerCase();

      let upload_root = rulesService.predict_s3Config().S3_UPLOAD_ROOT + "/";
      if (this.uploadDir !== "") {
        upload_root = upload_root + this.uploadDir + "/";
      }
      let FILE_HEADER =
        rulesService.predict_s3Config().S3_UPLOAD_ROOT + "-File";
      if (this.fileHeader !== "") {
        FILE_HEADER = this.fileHeader;
      }
      const renameFile = `${FILE_HEADER}_${getTime}.jpeg`; // 리네임 처리
      let upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: this.albumBucketName,
          Key: upload_root + renameFile,
          Body: element,
        },
      });

      console.log("", this.selectFile);

      let promise = upload.promise();

      promise.then(
        (data) => {
          // alert("Successfully uploaded photo.");
          // console.log(data);
          _self.$emit("uploadComplete", data);
          // let filename = data.Key.split(upload_root).join("");
          // this.reqData.img_name = filename;
          // this.reqData.URL = data.Location;
          // this.saveData();
        },
        (err) => {
          return alert(
            "There was an error uploading your photo: ",
            err.message
          );
        }
      );
    },
  },
};
</script>

<style lang="scss">
#file-input {
  display: none;
}
.fileNameBox {
  padding: 10px;
}
.profile-img {
  display: flex;
  text-align: left;
  margin-bottom: 20px;
  .img-wrap {
    position: relative;
    width: 3rem;
    height: 3rem;
    display: inline-block;
    .img-mask {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .v-btn {
      position: absolute;
      left: 0px;
      top: 0px;
      // background: #ffffff;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 1rem;
      border: 1px solid var(--orange);
      .v-icon {
        color: var(--orange);
      }
    }
  }
}
</style>