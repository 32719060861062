<template>
  <v-col cols="6">
    <base-card>
      {{ targetString }}
      <v-img
        class=""
        height="200"
        :src="topImg"
        alt=""
        gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
      />
      <div class="px-6 py-4">
        <div class="font-weight-bold text-h5 mb-2">{{ title }}</div>
        <p class="grey--text text--darken-3 body-1 ma-0">
          {{ subText }}
        </p>
      </div>
      <div class="imageBox-upload">
        <label
          class="v-btn"
          :for="`${targetString}_file`"
          outlined
          fab
          x-small
          color="primary"
        >
        </label>
        <input
          style="display: none"
          type="file"
          :accept="accept"
          :id="`${targetString}_file`"
          ref="file"
          @change="fileUploadHandler"
        />
      </div>
    </base-card>
  </v-col>
</template>
<script>
import $ from "jquery";
const rulesService = require("@/utils/rules.js");
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    subText: {
      type: String,
      default: "",
    },
    topImg: {
      type: String,
      default: require("@/assets/images/gallery/sq-13.jpg"),
    },
    targetString: {
      default: "",
      type: String,
    },
    // 업로드 할 디렉토리 * 없으면 빈값~
    uploadDir: {
      default: "",
      type: String,
    },
    // 파일해더 * 업로드 된 파일의 헤더(*필수로 넣어주세요) 예: RPM-File_YYYYMMDDHHmmss.jpg
    fileHeader: {
      default: "",
      type: String,
    },
    accept: {
      default: "",
      type: String,
    },
    maxSize: {
      default: 3200,
      type: Number,
    },
    zipRatio: {
      default: 0.5,
      type: Number,
    },
  },
  data() {
    return {
      albumBucketName: rulesService.predict_s3Config().S3_BUCKET,
      bucketRegion: rulesService.predict_s3Config().S3_REGION,
      IdentityPoolId: rulesService.predict_s3Config().S3_POOLID,
      selectFile: null,
      s3: null,
    };
  },
  created() {
    AWS.config.update({
      region: this.bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.IdentityPoolId,
      }),
    });
    this.s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: { Bucket: this.albumBucketName },
    });
  },
  methods: {
    _reset() {
      $("input[type=file]").val("");
    },
    // 첨부파일
    fileUploadHandler() {
      console.log(`${this.targetString}`);
      this.file = this.$refs.file.files[0];
      this.$emit("fileSelected", this.file);
      this.uploadFileToS3();
    },
    uploadFileToS3() {
      const _self = this;
      if (this.file === null) {
        this.$emit("uploadComplete", null);
        return;
      }
      const photoKey = this.file.name; // 파일명
      const getTime = this.$moment().format("YYYYMMDDHHmmss");
      const _lastDot = photoKey.lastIndexOf(".");
      const _fileExt = photoKey
        .substring(_lastDot, photoKey.length)
        .toLowerCase();

      let upload_root = rulesService.predict_s3Config().S3_UPLOAD_ROOT + "/";
      if (this.uploadDir !== "")
        upload_root = upload_root + this.uploadDir + "/";

      let FILE_HEADER =
        rulesService.predict_s3Config().S3_UPLOAD_ROOT + "-File";
      if (this.fileHeader !== "") FILE_HEADER = this.fileHeader;

      // 리네임 처리
      const renameFile = `${FILE_HEADER}_${getTime}${_fileExt}`;
      let upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: this.albumBucketName,
          Key: upload_root + renameFile,
          Body: this.file,
        },
      });

      let promise = upload.promise();

      promise.then(
        (data) => {
          // alert('Successfully uploaded photo.');
          // console.log(`${_self.fileHeader}_uploadComplete`, data);
          _self.$emit(`uploadComplete`, data, _self.targetString);
        },
        (err) => {
          return alert(
            "There was an error uploading your photo: ",
            err.message
          );
        }
      );
    },
  },
};
</script>
<style lang="scss">
// .imageBox-upload #file {
//   display: none;
// }
.imageBox-upload .v-btn {
  position: absolute;
  left: 0px;
  top: 0px;
  // background: #ffffff;
  width: 100%;
  height: 100%;
  cursor: pointer;
  // border-radius: 1rem;
  border: 1px solid var(--orange);
  .v-icon {
    color: var(--orange);
  }
}
</style>