<template>
  <v-tab-item :transition="false">
    <v-card flat class="editorPadding">
      <div class="top">
        <div class="tableTitle">{{ title }}</div>
      </div>

      <ClinicImageUpload ref="refClinicImageUpload" :clinicData="clinicData" />

      <div class="vGap" />

      <ClinicContentsUpload
        ref="refClinicContentsUpload"
        :clinicData="clinicData"
      />
    </v-card>
  </v-tab-item>
</template>
<script>
import ClinicImageUpload from "./contentsInfos/ClinicImageUpload.vue";
import ClinicContentsUpload from "./contentsInfos/ClinicContentsUpload.vue";
export default {
  components: {
    ClinicImageUpload,
    ClinicContentsUpload,
  },
  props: {
    clinicData: {
      type: [Object],
    },
  },
  data() {
    return {
      title: "병원이미지(Main/입구이미지) 관리",
    };
  },
  methods: {
    _infoUpdate() {
      setTimeout(() => {
        this.$refs.refClinicImageUpload._getData();
        this.$refs.refClinicContentsUpload._getUploadFiles();
      }, 300);
    },
  },
};
</script>
<style lang="">
</style>