<template>
  <v-row justify="center">
    <ContentsCard
      title="Main이미지"
      targetString="main"
      :topImg="data && data.mainImage"
      subText="* 비율-1:1, 해상도-최소 400X400, 최대 1,600X1,600)"
      @uploadComplete="uploadComplete"
    />

    <ContentsCard
      title="입구이미지"
      targetString="enter"
      :topImg="data && data.enterImage"
      subText="* 비율-16:9, 해상도-최소 400X225, 최대 3,200X1,800)"
      @uploadComplete="uploadComplete"
    />
  </v-row>
</template>
<script>
import { mapActions } from "vuex";
import ContentsCard from "./comp/ContentsCard.vue";
// const formatService = require("@/utils/format.js");
const modelService = require("@/utils/dataModel.js");

export default {
  components: {
    ContentsCard,
  },
  props: {
    clinicData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      data: null,
      targetModel: modelService.hospitalMainImagesModel(),
    };
  },
  methods: {
    _getData() {
      const $h = this.$helper;
      this.data = null;
      this.clinicData.image_id = 0;
      this.clinicData.endpoint = "hospitalMainImages";
      this.getDataQuery(this.clinicData).then((resP) => {
        if (resP === undefined) return;
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
            dpDateLabel: $h.changeDateToForUpdateDp(item.regDate, item.updDate),
          }));
        }
        this.data = res[0];
        console.log("", this.data);
        if (this.$refs.refClinicContentsUpload === undefined) return;
        this.$refs.refClinicContentsUpload._getUploadFiles(this.clinicData);
      });
    },
    uploadComplete(e, targetString) {
      console.log("uploadComplete", e.Location);
      if (targetString === "enter") {
        this.targetModel.enterImage = e.Location;
      } else {
        this.targetModel.mainImage = e.Location;
      }
      this.saveFiles();
    },
    saveFiles() {
      this.targetModel.hospital_id = this.clinicData.hospital_id;
      this.saveDataQuery(this.targetModel).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage("", "Saved", "success");
        this.$emit("saveComplete");
        this._getData();
      });
    },
    imgUploadComplete() {},
    imgChangeDeleteOpt() {},
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
.top {
  display: flex;
  margin-bottom: 10px;
}
.tableTitle {
  margin-right: auto;
  padding-top: 14px;
  font-weight: 700;
}
</style>