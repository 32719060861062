<template>
  <v-dialog v-model="visible" max-width="800px" scrollable>
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> {{ editType }} </v-card-title>
      <v-card-text id="eventInfo">
        <v-form ref="form">
          <TitleField :titleData="targetModel.eventName" label="이벤트상품명" :rules="[filedRules.required]" @complete="targetModel.eventName = $event"/>

          <!-- <v-row>
            <v-text-field label="이벤트상품명" type="text" ref="refProductName" v-model="targetModel.eventName" :rules="[filedRules.required]" />
          </v-row> -->
          <v-row>
            <SelectBoxProduct 
              ref="refSelectBoxProduct" 
              :clinicData="targetModel" 
              :selectedId="selectedProductIds" 
              @changeSelect="productTypeChangeSelect" />
          </v-row>
          
          <!-- <v-row>
            <SelectBoxProductTypeItem ref="refSelectBoxProductTypeItem" :clinicData="targetModel" :selectedTypeId="selectedProductTypeId" :selectedId="selectedProductTypeItemId"
              @changeSelect="productTypeItemChangeSelect" />
          </v-row> -->
          <!-- <v-row>
            <v-text-field
              label="약품/기기명"
              type="text"
              ref="refMedicineName"
              v-model="targetModel.medicineName"
            />
          </v-row>
          <v-row>
            <v-text-field
              label="약품/기기 원산지"
              type="text"
              ref="refMedicineOrigin"
              v-model="targetModel.medicineOrigin"
            />
          </v-row> -->
          <v-row>
            <v-text-field label="이벤트상품가격" type="text" ref="refProductPrice" v-model="targetModel.eventPrice" :rules="[filedRules.required]" />
          </v-row>
          <v-row>
            <v-text-field label="이벤트상품판매가격" type="text" ref="refProductSalePrice" v-model="targetModel.eventSalePrice" :rules="[filedRules.required]" />
          </v-row>
          <!-- <v-row>
            <v-text-field label="기기,약품소개" type="text" ref="refItemIntro" v-model="targetModel.itemIntro" />
          </v-row> -->

          <TextArea :titleData="targetModel.description" label="이벤트상품상세" @complete="targetModel.description = $event"/>

          <div class="vGap" />

          <TextArea :titleData="targetModel.effect" label="이벤트 효능, 기능" @complete="targetModel.effect = $event"/>

          <div class="vGap" />
          <!-- <v-row>
            <v-textarea outlined label="이벤트상품상세" counter="1000" maxLength="1000" v-model="targetModel.description" placeholder=""></v-textarea>
          </v-row> -->
          <!-- <v-row>
            <v-textarea outlined label="이벤트 효능, 기능" counter="1000" maxLength="1000" v-model="targetModel.effect" placeholder=""></v-textarea>
          </v-row> -->
          <!-- <v-row>
            <RadioComps :items="isCanTaxRefundItem" :prosSelected="isCanTaxRefund" @changeType="isCanTaxRefundChangeType" />
          </v-row> -->

          <BeforeAndAfterUpload 
            label="이벤트정보 파일업로드"
            ref="refBeforeAndAfterUpload" 
            @checkTargetFiles="checkTargetFiles" />
        </v-form>
      </v-card-text>

      <CommonBtn :propsKey="targetModel.event_id" @save="clickSave" @delete="deleteData" @cancel="clickCancel" />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "./comp/Header.vue";
import CommonBtn from "./medicalInfo/comp/CommonBtn.vue";
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
import BeforeAndAfterUpload from "./comp/BeforeAndAfterUpload.vue";

import TitleField from "@/components/commonV2/ui/lang/TitleField.vue";
import TextArea from "@/components/commonV2/ui/lang/TextArea.vue";
import SelectBoxProduct from "@/components/commonV2/ui/SelectBoxProduct.vue";
// import SelectBoxProductType from "@/components/commonV2/ui/SelectBoxProductType.vue";
// import SelectBoxProductTypeItem from "@/components/commonV2/ui/SelectBoxProductTypeItem.vue";
import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
const rulesService = require("@/utils/rules.js");
const modelService = require("@/utils/dataModel.js");
const formatService = require("@/utils/format.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    Header,
    CommonBtn,
    SelectBox,
    BeforeAndAfterUpload,
    SelectBoxProduct,
    // SelectBoxProductType,
    // SelectBoxProductTypeItem,
    RadioComps,
    TitleField,
    TextArea
  },
  data() {
    return {
      visible: false,
      editType: "",
      selectedProductIds: [],
      // selectedProductTypeItemId: 0,
      itemsProductType: [], //formatService.productTypeList(),
      isCanTaxRefund: "Y",
      isCanTaxRefundItem: formatService.isCanTaxRefundItem(),
      serviceList: formatService.serviceList(),
      diagnosisList: formatService.diagnosisList(),
      requiredMsg: requireService.requiredFieldAlertMessage(),
      targetModel: modelService.hospitalEventModel(),
      filedRules: rulesService.filedRules(),
      clinicData: null,
    };
  },
  methods: {
    showModal(editType, clinicData, item) {
      this.clinicData = clinicData;
      this.visible = true;
      this.editType = editType;
      this.targetModel = item === null? modelService.hospitalEventModel():item;
      this.targetModel.hospital_id = clinicData.hospital_id;
      this.targetModel.endpoint = "hospitalEvent";

      this.selectedProductIds = [];
      setTimeout(() => {
        this.ScrollTop();
        this.$refs.refSelectBoxProduct.getProductData(clinicData);
        this.$refs.refBeforeAndAfterUpload._getUploadFiles(clinicData, item === null ? 0:item.event_id);
        
        if(item === null) return;
        item.productsDto.forEach(element => {
          this.selectedProductIds.push(element.product_id);
        });
      }, 300);
    },
    close() {
      this.visible = false;
    },
    isCanTaxRefundChangeType(e) {
      this.targetModel.isCanTaxRefund = e;
    },
    productTypeChangeSelect(e) {
      this.selectedProductIds = e;
      console.log('selectedProductIds', e);
    },
    checkTargetFiles(e) {
      this.targetModel.uploadFiles = e;
      // hospital_id / product_id추가
      if (this.targetModel.uploadFiles.length > 0) {
        this.targetModel.uploadFiles.forEach((element) => {
          element.hospital_id = this.targetModel.hospital_id;
          element.product_id = this.targetModel.product_id;
        });
      }
    },
    clickSave() {
      // console.log("saveData", this.targetModel);
      // return;
      const $h = this.$helper;
      const D = this.targetModel;
      let RF = this.$refs;

      console.log('D', D);
      
      let msg = `상품 ${this.requiredMsg}`;
      if(D.products.length === 0) {
        $h.showTostMessage('', msg, 'error');
        return
      }

      // msg = `Drug Name ${this.requiredMsg}`;
      // if (!$h.requiredCheck(D.drugName, null, msg, false)) return;

      if (!this.$refs.form.validate()) return;

      // 가격정보 , -> 삭제
      D.eventPrice = D.eventPrice.toString().replaceAll(",", "");
      D.eventSalePrice = D.eventSalePrice.toString().replaceAll(",", "");
      // return;
      this.saveData();
    },
    clickCancel() {
      this.visible = false;
    },
    deleteData() {
      if (!confirm("delete?")) return;
      this.targetModel.useYN = "N";
      this.saveData();
    },
    saveData() {
      // console.log('saveData', this.targetModel);
      this.targetModel.products = this.selectedProductIds;
      this.saveDataQuery(this.targetModel).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage("", "Saved", "success");
        this.visible = false;
        this.$emit("saveComplete");
      });
    },
    ScrollTop() {
      console.log("Scroll");
      const scrollTarget = document.getElementById("eventInfo");
      scrollTarget.scrollTo({ top: 0, behavior: "smooth" });
    },
    ...mapActions(["saveDataQuery"]),
  },
};
</script>