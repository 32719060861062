<template>
  <v-card flat>
    <v-card-text>
      <h1>예약일정 : 2024년 05월 30일 오후 02:30</h1>
      <p>
        <v-btn color="primary" depressed small @click="viewImage"
          >첨부된 여권정보사진 보기</v-btn
        >
      </p>
      <v-row>
        <v-col cols="12" md="12" align="center">
          <v-btn color="primary" depressed large @click="approveOrReject"
            >일정승인(반려)</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    clinicData: {
      type: [Object, Array],
    },
  },
  methods: {
    viewImage() {},
    approveOrReject() {},
  },
};
</script>
<style lang="">
</style>