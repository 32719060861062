<template>
  <v-dialog v-model="visible" max-width="800px" scrollable>
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> {{ editType }} </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <S3FileUpload
              ref="userImgS3FileUploader"
              uploadType="profile"
              accept="image/png, image/jpeg, image/bmp, image/gif"
              defLabel="이미지"
              uploadDir="director"
              fileHeader="profileDirectorImg"
              defImage="profile_man"
              :fileDeleteOpt="fileDeleteOpt"
              :fileInfo="targetModel.imgFileInfo"
              @uploadComplete="imgUploadComplete"
              @changeDeleteOpt="imgChangeDeleteOpt"
            />
          </v-row>
          <v-row>
            <TitleField 
              label="원장님성함" 
              :titleData="targetModel.directorName" 
              :rules="[filedRules.required]" 
              @complete="targetModel.directorName = $event"/>
          </v-row>
          <v-row>
            <v-textarea
              outlined
              label="약력(한글)"
              counter="10000"
              maxLength="10000"
              v-model="targetModel.profile_ko"
              placeholder="* 주 전공분야, 약력, 수상, 관련기사 등 "
              :rules="[filedRules.required]"
            />
          </v-row>
          <v-row>
            <v-textarea
              outlined
              label="약력(영어)"
              counter="10000"
              maxLength="10000"
              v-model="targetModel.profile_en"
              placeholder="* 주 전공분야, 약력, 수상, 관련기사 등 "
            ></v-textarea>
          </v-row>
          <v-row>
            <v-textarea
              outlined
              label="약력(중국어)"
              counter="10000"
              maxLength="10000"
              v-model="targetModel.profile_cn"
              placeholder="* 주 전공분야, 약력, 수상, 관련기사 등 "
            ></v-textarea>
          </v-row>
        </v-form>
      </v-card-text>

      <CommonBtn
        :propsKey="targetModel.director_id"
        @save="clickSave"
        @delete="deleteData"
        @cancel="clickCancel"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "./comp/Header.vue";
import CommonBtn from "./medicalInfo/comp/CommonBtn.vue";
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
import BeforeAndAfterUpload from "./comp/BeforeAndAfterUpload.vue";

import S3FileUpload from "@/components/commonV2/ui/S3_ForFileUpload.vue";
import SelectBoxProductType from "@/components/commonV2/ui/SelectBoxProductType.vue";
import SelectBoxProductTypeItem from "@/components/commonV2/ui/SelectBoxProductTypeItem.vue";
import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
import TitleField from "@/components/commonV2/ui/lang/TitleField.vue";

const rulesService = require("@/utils/rules.js");
const modelService = require("@/utils/dataModel.js");
const formatService = require("@/utils/format.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    Header,
    CommonBtn,
    SelectBox,
    BeforeAndAfterUpload,
    SelectBoxProductType,
    SelectBoxProductTypeItem,
    RadioComps,
    S3FileUpload,
    TitleField
  },
  data() {
    return {
      visible: false,
      editType: "",
      // selectedProductTypeId: 0,
      // selectedProductTypeItemId: 0,
      // itemsProductType: [], //formatService.productTypeList(),
      // isCanTaxRefund: "Y",
      // isCanTaxRefundItem: formatService.isCanTaxRefundItem(),
      // serviceList: formatService.serviceList(),
      // diagnosisList: formatService.diagnosisList(),
      requiredMsg: requireService.requiredFieldAlertMessage(),
      targetModel: modelService.hospitalDirectorsModel(),
      filedRules: rulesService.filedRules(),
      clinicData: null,
      imgFileInfo: null,
      fileDeleteOpt: false,
      fileDeleteRequestImg: false, // 저장 실행여부(파일삭제처리 오류)
    };
  },
  methods: {
    showModal(editType, clinicData, item) {
      this.clinicData = clinicData;
      this.visible = true;
      this.editType = editType;
      const $h = this.$helper;
      if (item === null) {
        this.targetModel = modelService.hospitalDirectorsModel();
      } else {
        const img = item.userPhotoImg;
        if (img !== "" && img !== null) {
          item.imgFileInfo = {
            filename: $h.findFileName(img),
            fullURL: img,
            isImageFile: $h.checkImageFile($h.findFileName(img)),
          };
        }
        this.targetModel = item;
      }
      this.targetModel.hospital_id = clinicData.hospital_id;
      this.targetModel.endpoint = "hospitalDirectors";

      setTimeout(() => {
        // this.$refs.refSelectBoxProductType.getProductTypeData(clinicData);
        // this.$refs.refSelectBoxProductTypeItem.getProductTypeItemData(
        //   clinicData,
        //   this.selectedProductTypeId
        // );
        // this.$refs.refBeforeAndAfterUpload._getUploadFiles(
        //   clinicData,
        //   item.product_id
        // );
      }, 300);
    },
    close() {
      this.visible = false;
    },
    // isCanTaxRefundChangeType(e) {
    //   this.targetModel.isCanTaxRefund = e;
    // },
    // productTypeChangeSelect(e) {
    //   this.selectedProductTypeId = e;
    //   this.targetModel.type_id = e;
    //   this.$refs.refSelectBoxProductTypeItem.getProductTypeItemData(
    //     this.clinicData,
    //     e
    //   );
    // },
    // productTypeItemChangeSelect(e) {
    //   this.targetModel.item_id = e;
    // },
    // checkTargetFiles(e) {
    //   this.targetModel.uploadFiles = e;
    //   // hospital_id / product_id추가
    //   if (this.targetModel.uploadFiles.length > 0) {
    //     this.targetModel.uploadFiles.forEach((element) => {
    //       element.hospital_id = this.targetModel.hospital_id;
    //       element.product_id = this.targetModel.product_id;
    //     });
    //   }
    // },
    clickSave() {
      // console.log("saveData", this.targetModel);
      // return;
      const $h = this.$helper;
      const D = this.targetModel;
      let RF = this.$refs;
      // let msg = `상품타입 ${this.requiredMsg}`;
      // if (!$h.requiredCheck(D.type_id, null, msg, false)) return;

      // msg = `Drug Name ${this.requiredMsg}`;
      // if (!$h.requiredCheck(D.drugName, null, msg, false)) return;

      if (!this.$refs.form.validate()) return;
      // return;
      this.saveData();
    },
    clickCancel() {
      this.visible = false;
    },
    deleteData() {
      if (!confirm("delete?")) return;
      this.targetModel.useYN = "N";
      this.saveData();
    },
    saveData() {
      // console.log('saveData', this.targetModel);
      this.saveDataQuery(this.targetModel).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage("", "Saved", "success");
        this.visible = false;
        this.$emit("saveComplete");
      });
    },
    // S3업로드 처리 후
    imgUploadComplete(e) {
      console.log("imgUploadComplete", e);
      // console.log('첨부파일이 선택되지 않은경우임 -> 다음단계로 이동 처리');
      const $h = this.$helper;
      if (e !== null) {
        this.targetModel.userPhotoImg = e.Location;
        this.targetModel.imgFileInfo = {
          filename: $h.findFileName(e.key),
          fullURL: e.Location,
          isImageFile: $h.checkImageFile($h.findFileName(e.key)),
        };
      }
      // console.log("this.data.userPhotoFileInfo", this.data.userPhotoFileInfo);
      this.$forceUpdate();
    },
    imgChangeDeleteOpt(e) {
      this.fileDeleteRequestImg = e;
    },
    ...mapActions(["saveDataQuery"]),
  },
};
</script>